import React from "react";
import styled from "styled-components";
import H2 from "../../DS/Typo/H2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import InfoCard from "../InfoCard/InfoCard";
import LightDivider from "../LightDivider/LightDivider";
import mark from "../../assets/images/mark.png";
const MarkImg = styled.img`
  position: absolute;
  top: 200%;
  right: 0;
  width: 110px;
`;
const data = [
  [
    {
      name: "Mary Ellen Pinion",
      title: "Cullman County President",
      email: "mepinion@msn.com",
      phone: ["256-287-0938"],
      address:
        "2379 County Road 101, Bremen, AL 35033",
    },
    {
      name: "Elaine McCord",
      title: "Athens/Limestone County President",
      email: "ieemccord@gmail.com",
      phone: ["256-777-1399"],
      address:
        "15854 Ham Road, Athens, AL 35611",
    },
    {
      name: "Donna Jones",
      title: "Winston County President",
      email: "jonesfourth@yahoo.com",
      phone: ["256-577-8581"],
      address:
        "706  35th Street, Haleyville, AL 35565",
    },
    {
      name: "Dr. Randy Pettus",
      title: "Florence/Lauderdale County President",
      email: "rjpettus@gmail.com",
      phone: ["256-710-8822"],
      address:
        "835 Riverview Drive Florence, AL 35630",
    },
    {
      name: "Dr. Delaina Greene",
      title: "Lawrence County President",
      email: "delainagreene@gmail.com",
      phone: ["256-710-0866"],
      address:
        "21990 Alabama Highway 157, Town Creek, AL 35672",
    },
    {
      name: "Cully J. Hartsell (Acting President)",
      title: "Marion County President",
      email: "cjhartms69@gmail.com",
      phone: ["662-652-4080", "205-412-5649"],
      address:
        "609 Hartsell Road Tremont, MS  38876",
    },
    {
      name: "Rex Cheatham",
      title: "Morgan County President",
      email: "Rex.rexcheatham@yahoo.com",
      phone: ["256-565-2027"],
      address:
        "1904 Resnick Drive Decatur, AL 35603",
    },
    {
      name: "Judy Claunch",
      title: "Colbert County President",
      email: "jlclaunch@comcast.net",
      phone: ["256-394-2666"],
      address:
        "616 N. Cave Street Tuscumbia, AL 35674",
    },
    {
      name: "Sandy Gibson",
      title: "Franklin County President",
      email: "chuckgib@bellsouth.net",
      phone: ["256-332-1830", "256-810-6917"],
      address:
        "1007 Spencer Way Tuscumbia, AL 35674",
    },
  ],
  [
    {
      name: "Rebecca Arrington",
      title: "DeKalb County President",
      email: "mrsfrizz2002@yahoo.com",
      phone: ["256-717-5667"],
      address:
        "298 County Road 122, Fort Payne, AL 35968",
    },
    {
      name: "Nancy Mitchell",
      title: "Etowah County President",
      email: "nmitchell54@bellsouth.net",
      phone: ["256-504-7660"],
      address:
        "501 North 4th Place, Gadsden, AL 35901",
    },
    {
      name: "Teresa Noell",
      title: "Cleburne County President",
      email: "tnoellaera@gmail.com",
      phone: ["256-225-5456"],
      address:
        "220 Hathaway Heights, Anniston, AL 36207",
    },
    {
      name: "Dr. Toni McGriff",
      title: "Jackson County President",
      email: "tonimcgriff@gmail.com",
      phone: ["256-228-3520"],
      address:
        "3733 County Road 124, Dutton, AL 35744",
    },
    {
      name: "Otis Threatt",
      title: "Madison County President",
      email: "ozthreatt@comcast.net",
      phone: ["256-852-7074"],
      address:
        "4049 Knollbrook Drive Huntsville, Alabama 35810",
    },
    {
      name: "Stanley Mahan",
      title: "Marshall County President",
      email: "sdmahan1@gmail.com",
      phone: ["256-506-1975"],
      address:
        "14 Johnson Drive, Albertville, AL 35950",
    },
    {
      name: "Judy Bell",
      title: "Calhoun County President",
      email: "Mrbell4@juno.com",
      phone: ["256-435-9135"],
      address: "78 Silver Circle Jacksonville, AL 36265",
    },
    {
      name: "Ann Beddingfield",
      title: "Cherokee County President",
      email: "annkb@tds.net",
      phone: ["256-927-7046", "256-504-9428"],
      address:
        "1340 County Road 14 Piedmont, AL 36275",
    },
  ],
  [
    {
      name: "Martha Jo McDonald",
      title: "Fayette County President",
      email: "marthajomcd@gmail.com",
      phone: ["205-270-0551"],
      address: "2216 6th Avenue NW, Fayette, AL 35555",
    },
    {
      name: "Ann Corder",
      title: "Pickens County President",
      email: "acorder1908@yahoo.com",
      phone: ["205-399-0097"],
      address:
        "5240 County Road 2, Aliceville, AL 35442",
    },
    {
      name: "Genette Presswood",
      title: "Sumter County President",
      email: "ajp@uwa.edu",
      phone: ["205-392-7522","205-499-1682"],
      address: "1010 4th Avenue, York, AL 36925",
    },
    {
      name: "Mary K. Bell",
      title: "Hale County President",
      email: "",
      phone: ["334-624-8656"],
      address:
        "141 Finch Drive Greensboro, AL 36744",
    },
    {
      name: "Elaine Rushing",
      title: "Lamar County President",
      email: "earline00@yahoo.com",
      phone: ["205-662-4835"],
      address: "18 Rushing Millport, AL 35576",
    },
    {
      name: "Velma Essex",
      title: "Perry County President",
      email: "Veej_36756@yahoo.com",
      phone: ["205-394-3084"],
      address:
        "P.O. Box 974, Marion, AL 36756-0974",
    },
    {
      name: "Dr. Jim Jolly",
      title: "Tuscaloosa County President",
      email: "jimjolly95@gmail.com",
      phone: ["205-561-8210"],
      address:
        "11273 Woodbank Parkway Tuscaloosa, AL 35405",
    },
    {
      name: "Vickie Brown",
      title: "Bibb County",
      email: "Bgbrown06@gmail.com",
      phone: ["205-316-8998"],
      address:
        "532 Ward Avenue Brent, AL 35034",
    },
    {
      name: "Loydleetta Wabbington",
      title: "Greene County President",
      email: "Ljwabbington12@gmail.com",
      phone: ["205-372-3704"],
      address: "P.O. Box 254 Eutaw, AL  35462",
    },
  ],
  [
    {
      name: "Diane Palmer",
      title: "Blount County President",
      email: "dianepalmer1947@yahoo.com",
      phone: ["205-532-6173"],
      address:
        "1930 Alabama Avenue, Oneonta, AL 35121",
    },
    {
      name: "Jacquelyn Oglesby",
      title: "Jefferson County President",
      email: "jtoglesby3@gmail.com",
      phone: ["205-424-8318", "205-230-3020"],
      address:
        "5381 Summerset Way, Bessemer, AL 35022",
    },
    {
      name: "Bobby J. Pierson",
      title: "Shelby County President",
      email: "qmega4@charter.net",
      phone: ["205-915-0787"],
      address:
        "1131 Oak Street, Montevallo, AL 35115",
    },
    {
      name: "Carolyn Jones",
      title: "St. Clair County President",
      email: "",
      phone: ["205-629-6000"],
      address:
        "146 Pleasant Circle Odenville, AL 35120",
    },
    {
      name: "Nancy Lehe",
      title: "Talladega County President",
      email: "nancylehe@att.net",
      phone: ["256-362-8647"],
      address:
        "115 Morgan Street Talladega, AL 35160",
    },
    {
      name: "Gail Alexander",
      title: "Walker County President",
      email: "Gailalexander1950@yahoo.com",
      phone: ["205-387-2086"],
      address:
        "1275 Alexander Tubbs Road Jasper, AL 35503-3314",
    },
  ],
  [
    {
      name: "Gretchen Holloway",
      title: "Chambers County President",
      email: "hollowaygm@yahoo.com",
      phone: ["706-590-4916"],
      address:
        "6286 Fairfax Bypass, Valley, AL 36854",
    },
    {
      name: "Leon Robinson",
      title: "Elmore County President",
      email: "llrobiinson@yahoo.com",
      phone: ["334-399-4240"],
      address: "3149 S. Rick Drive, Montgomery, AL 36108",
    },
    {
      name: "Pam Hendrick",
      title: "Lee County President",
      email: "pamgregory@charter.net",
      phone: ["334-559-0462"],
      address:
        "513 Cloverdale Drive, Auburn, AL 36830",
    },
    {
      name: "Grace Wood",
      title: "Clay County President",
      email: "gracewood@centurytel.net",
      phone: ["256-488-5567"],
      address:
        "2202 Fosters Road Delta, AL 36258-5544",
    },
    {
      name: "Calvin McKinney",
      title: "Coosa County President",
      email: "",
      phone: ["256-377-4605", "256-307-2395"],
      address:
        "2939 County Road 30 Kellyton, AL 35089",
    },
    {
      name: "Dr. Lemoyen Shellnutt",
      title: "Randolph County President",
      email: "lemoyhunt@aol.com",
      phone: ["256-589-1794"],
      address:
        "85 Amanda Drive, Wedowee, AL 36278",
    },
    {
      name: "Johnny Cunningham",
      title: "Tallapoosa County President",
      email: "johnny254@charter.net",
      phone: ["256-392-3960"],
      address:
        "254 Fishpond Road, Alexander City, AL 35010",
    },
    {
      name: "Teresa Bufkin",
      title: "Autauga County President",
      email: "Teresa.bufkin@yahoo.com",
      phone: ["334-300-2314", "334-361-0417"],
      address:
        "348 County Road 17 Autaugaville, AL 36003",
    },
    {
      name: "Jim Shannon",
      title: "Chilton County President",
      email: "brojimshannon@gmail.com",
      phone: ["205-294-4425"],
      address:
        "855 County Road 184 Clanton, AL  35046",
    },
  ],
  [
    {
      name: "Joy Seybold",
      title: "Choctaw County President",
      email: "jseybold@tds.net",
      phone: ["205-459-3783"],
      address: "616 Sund Drive Butler, AL 36904",
    },
    {
      name: "Renee Smith",
      title: "Clarke County President",
      email: "rmsmenu1@gmail.com",
      phone: ["251-513-5732"],
      address:
        "200 Smith Ridge Road, Jackson, AL 36545",
    },
    {
      name: "Jeanie Ward",
      title: "Selma/Dallas County President",
      email: "Jeanie_ward@yahoo.com",
      phone: ["334-872-1761", "334-505-1617"],
      address: "301 Ruth Street Selma, AL 36701",
    },
    {
      name: "Judy Compton",
      title: "Marengo County President",
      email: "Judycompton043@yahoo.com",
      phone: ["334-295-8824", "334-216-5335"],
      address:
        "P.O. Box 480043 Linden, AL 36748-0043",
    },
    {
      name: "Cleophus Stephens",
      title: "Washington County President",
      email: "jpstep1963@gmail.com",
      phone: ["251-847-2802"],
      address:
        "Post Office Box 59 Chatom, AL 36518",
    },
    {
      name: "Marvin Carter",
      title: "Wilcox County President",
      email: "mecarter@frontiernet.net",
      phone: ["334-303-4785", "334-682-5050"],
      address: "P.O. Box 362 Camden, AL 36726",
    },
  ],
  [
    {
      name: "Zack Buckner",
      title: "Montgomery County President",
      email: "zbuckner@aol.com",
      phone: ["334-322-5209"],
      address:
        "P.O. Box 231111, Montgomery, AL 36123",
    },
    {
      name: "Vivian Covington",
      title: "Russell County President",
      email: "holtcovington@gmail.com",
      phone: ["334-540-2551", "434-667-7771"],
      address:
        "201 Long Street, Hurtsboro, Alabama  36860",
    },
    {
      name: "Rev. Abbie L. Jackson",
      title: "Butler County President",
      email: "",
      phone: ["334-382-5801", "334-652-1987"],
      address:
        "1277 Owens Road Greenville, AL 36037",
    },
    {
      name: "Gwen Peterson",
      title: "Lowndes County President",
      email: "gwynnovations@yahoo.com",
      phone: ["334-300-0636"],
      address:
        "2136 Beverly Drive, Montgomery, AL 36111-2704",
    },
    {
      name: "Mae Doris Williams",
      title: "Macon County President",
      email: "mdw.idb@gmail.com",
      phone: ["334-727-1304", "334-339-0523"],
      address:
        "Post Office Box 1173 Tuskegee Institute, AL 36087",
    },
    {
      name: "Dr. Ruby Jackson",
      title: "Barbour County President",
      email: "rubymj1@aol.com",
      phone: ["334-397-4617"],
      address:
        "2001 County Road 33 Clio, AL 36017",
    },
    {
      name: "James Cooks",
      title: "Bullock County President",
      email: "jcooks@ustconline.net",
      phone: ["334-738-4349"],
      address:
        "17271 Highway 82 Union Springs, AL 36089",
    },
  ],
  [
    {
      name: "Hilda Jenkins",
      title: "Baldwin County President",
      email: "hijenk@bellsouth.net",
      phone: ["251-422-1468"],
      address:
        "P.O. Box 862, Daphne, AL 36526",
    }, 
    {
      name: "Charlotte Boyle",
      title: "Escambia County Acting President",
      email: "cybole@hotmail.com",
      phone: ["251-767-1058"],
      address:
        "P.O. Box 801, Atmore, AL  36504",
    },
    {
      name: "John Paul Jones",
      title: "Mobile County President",
      email: "johnpaul.aera@gmail.com",
      phone: ["251-689-1126"],
      address:
        "5312 Oak Bend Court, Mobile, AL 36609",
    },
    {
      name: "Derrick Pettaway",
      title: "North Mobile County President",
      email: "derpet@msn.com",
      phone: ["251-645-8850"],
      address:
        "P.O. Box 70012, Mobile, AL 36670",
    },
    {
      name: "Carolyn Lee",
      title: "Monroe County President",
      email: "carolynsnelllee@hotmail.com",
      phone: ["251-282-9837"],
      address: "Post Office Box 1652 Monroeville, AL 36461",
    },
    {
      name: "Arnita Holder",
      title: "Conecuh County President",
      email: "",
      phone: ["251-752-1784"],
      address:
        "2054 Loree Road Evergreen, AL 36401",
    },
  ],
  [
    {
      name: "Marilyn Norsworthy",
      title: "Crenshaw County President",
      email: "mhnorsworthy@gmail.com",
      phone: ["334-372-7732"],
      address:
        "P.O. Box 155, Glenwood, AL 36034",
    },
    {
      name: "Aretha Harper",
      title: "Dale County President",
      email: "ojoyharp@hotmail.com",
      phone: ["334-301-7474"],
      address:
        "P.O. Box 1263, Ozark, AL 36360",
    },
    {
      name: "Don Spivey",
      title: "Dothan/Houston County President",
      email: "janiedoodle39@gmail.com",
      phone: ["334-726-5546"],
      address:
        "239 Jim Baxley Road, Slocumb, AL 36375",
    },
    {
      name: "Tellis R. Hill",
      title: "Pike County President",
      email: "csmthill@yahoo.com",
      phone: ["334-670-0822", "334-268-8199"],
      address:
        "1867 County Road 7707, Troy, AL 36081",
    },
    {
      name: "Lesa Knowles",
      title: "Henry County President",
      email: "ljknowles@hotmail.com",
      phone: ["334-714-0154"],
      address:
        "3615 Highway 134 Headland, AL 36345",
    },
    {
      name: "Barbara Cole",
      title: "Coffee County President",
      email: "colebarbarab@gmail.com",
      phone: ["334-389-6335"],
      address:
        "P.O. Box 471 New Brockton, AL 36351",
    },
    {
      name: "Margaret Sanders",
      title: "Covington County President",
      email: "Sandersmg1951@gmail.com",
      phone: ["334-222-5382", "334-488-3529"],
      address:
        "P.O. Box 1016 Andalusia, AL 36420",
    },
    {
      name: "Kathi Campbell",
      title: "Geneva County President",
      email: "Kdcampbell71@yahoo.com",
      phone: ["817-913-4029"],
      address:
        "1218 County Road 651 Coffee Springs, AL 36318",
    },
  ],
  [
    {
      name: "Lucy LaMar",
      title: "AURA President",
      email: "lll0003@auburn.edu",
      phone: ["334-750-8657"],
      address: "727 Burke Place, Auburn, AL 36830",
    },
    {
      name: "Dr. Kevin Whitaker",
      title: "TUARA President",
      email: "",
      phone: [],
      address:
        "5381 Courtney Avenue, Tuscaloosa, AL 35406-2874",
    },
    {
      name: "Reba Davis",
      title: "TURA President",
      email: "",
      phone: ["334-268-9917"],
      address:
        "3758 U.S. Highway 231, Brundidge, AL 36010-2469",
    },
    {
      name: "Doug Rigney",
      title: "UABRA President",
      email: "drigney@samford.edu",
      phone: ["205-224-5100"],
      address:
        "3845 River Run Trail Vestavia, AL 35243",
    },

    {
      name: "John Fix",
      title: "UAHRA President",
      email: "",
      phone: [],
      address: "",
    },
  ],
];

const LocalUnitsContainer = styled.div``;

const LocalUnits = () => {
  return (
    <LocalUnitsContainer>
      {data.map((row, index) => {
        return (
          <div key={index}>
            <Wrapper
              type="m"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom="53px"
            >
              <H2 style={{ textAlign: "center" }}>
                District {index + 1}
              </H2>
            </Wrapper>
            <Wrapper
              type="m"
              display="flex"
              style={{
                flexWrap: "wrap",
              }}
              marginBottom="50px"
            >
              {row.map((d, index) => {
                return (
                  <InfoCard
                    key={index}
                    heading={d.title}
                    title={d.name}
                    phone={d.phone}
                    mail={d.email}
                    address={d.address}
                  />
                );
              })}
            </Wrapper>
          </div>
        );
      })}
      <LightDivider marginTop="60px" />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <EmailSubscribe />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </LocalUnitsContainer>
  );
};

export default LocalUnits;
